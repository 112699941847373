@import '~antd/dist/reset.css';

.fontLoaded {
  font-family: 'Source Sans Pro', sans-serif;
}
.flex-scroll-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden auto;
}
.flex-scroll-content {
  flex: 1;
  overflow: hidden auto;
}
.ant-list-item-meta-title {
  margin-bottom: -4px;
}
.ant-modal {
  top: 20px;
}
.ant-card,
.ant-modal-content {
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 3px;
  border-radius: 8px;
}

.card-no-shadow {
  box-shadow: none;
}
.ant-layout {
  background-color: #fafafa;
}
/* .ant-btn {
  border-radius: 25px;
} */
.ant-table-body {
  overflow: auto !important;
}
.ant-drawer-title,
.ant-modal-title {
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-modal-body {
  overflow: hidden auto;
  max-height: 86vh;
}
.ant-drawer-title {
  display: block;
  width: 450px;
}
.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden auto;
}
.ant-menu {
  flex: 1;
  overflow: hidden auto;
}
.ant-menu-submenu-popup {
  overflow: unset !important;
}
.adm-pull-to-refresh {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden auto;
}
.fitImageToContent img {
  max-width: 100%;
}
.fitImageToContent video {
  max-width: 100%;
}
.removeActionMargin .ant-list-item-action {
  margin-left: 0;
}
.wrapTextEllipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
button {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center;
}
.anticon svg {
  vertical-align: baseline;
}
.extraActionBtn {
  transition: opacity 1s;
  position: fixed;
}
.hide {
  visibility: hidden;
  opacity: 0;
}
.logo-img-styles {
  margin: 0 6px 0 0px;
  height: auto;
}
.custom-drawer-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
.custom-drawer-footer .custom-footer-button {
  border-radius: 0px;
  width: 50%;
  font-size: 18px;
  height: 44px;
}
.ant-form-vertical .ant-form-item-label > label {
  width: 100%;
}
.pac-container {
  font-family: 'Source Sans Pro', sans-serif;
  z-index: 999999 !important;
}
.pac-item,
.pac-item-query {
  cursor: pointer;
}
.ant-tree-treenode,
.ant-tree-treenode-switcher-close,
.ant-tree-node-content-wrapper,
.ant-tree-node-content-wrapper {
  width: 100%;
}
.adm-action-sheet-button-item:active {
  background-color: transparent !important;
}
.custom-list-item-meta {
  margin-bottom: 4px;
}
.custom-list-item-meta .ant-list-item-meta-avatar {
  margin: auto;
}
.custom-list-item-meta .ant-list-item-meta-content {
  margin-left: 8px;
}
.custom-list-item-meta .ant-list-item-meta-description {
  font-size: 12px;
  line-height: 1;
}
[data-theme='compact']
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 12px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}
